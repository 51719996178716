import axios from "axios";
import {getCookie, getHost, setCookie} from "@/hooks/fnHelper";

const useServerRouters = async (tries = 0) => {
    const host = getHost();
    try {
        // console.log('getCookie(\'server_sid\')', getCookie('server_sid'));
        console.log('useServerRouters host', host);
        if (!getCookie('server_sid')) {
            try {
                const {data: apiData} = await axios.get('https://api.polyanaski.ru/API/');
                if(!apiData?.session?.sid){
                    throw new Error('No sid');
                }
                setCookie('server_sid', apiData.session.sid, "/", host);
            } catch (e) {
                if(tries < 5){
                    return await useServerRouters(++tries);
                }
            }
        }

        const {data} = await axios.get('https://svod.polyanaski.ru/api/market/salegroups', {
            headers: {
                'mrktsid': getCookie('server_sid') || ""
            },
            timeout: 4000
        });
        return data;
    } catch (e) {
        console.log('useServerRouters e', e)
        throw e;
    }
}

export default useServerRouters;