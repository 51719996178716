<script>
export default {
    props: {
        qrData: Object | Boolean,
    },
    data() {
        return {
            timerInt: null,
            nowDate: Date.now(),
            isTimeOut: false
        }
    },
    watch: {
        'qrData.endDate': {
            immediate: true,
            handler(newVal) {
                if (this.timerInt) {
                    clearInterval(this.timerInt)
                }
                this.timerInt = setInterval(() => {
                    this.nowDate = Date.now()
                    if (this.negative)
                        return
                    if (this.nowDate > newVal) {
                        this.nowDate = newVal;
                        this.isTimeOut = true;
                        clearInterval(this.timerInt)
                    }
                }, 1000)
            }
        },
        qrData: function (val){
            if(val === false){
                this.$emit('close');
            }
        }
    },
    computed: {
        min() {
            let m = Math.trunc((this.qrData.endDate - this.nowDate) / 1000 / 60) % 60;
            return m > 9 ? m : '0' + m;
        },
        sec() {
            let s = Math.trunc((this.qrData.endDate - this.nowDate) / 1000) % 60
            return s > 9 ? s : '0' + s;
        }
    },
    methods: {},
    unmounted() {
        console.log('unmounted');
        clearInterval(this.timerInt);
        this.checkCodeInt = null;
    }
}
</script>

<template>
    <div class="popup" data-id="gid-client-code">
        <div class="popup-container gid-client-code">
            <div class="content">
                <template v-if="qrData">
                    <div class="text">
                        <img :src="qrData.link" alt="">

                        <div class="head">
                            <span>QR-код скидки ГИД</span>
                        </div>

                        <p>Предъявите данный QR-код кассиру на территории Курорта&nbsp;«Газпром&nbsp;Поляна»</p>
                        <p v-if="parseInt(min)" class="hint">QR-код действителен: {{ min }}:{{ sec }}</p>
                        <p v-else class="hint">QR-код действителен: менее минуты</p>
<!--                        <p v-else class="hint">Срок действия QR-кода истек</p>-->
                    </div>
                </template>
                <div class="content-box content-box-i wait-box" v-else>
                    <div class="wait-icon">
                        <img src="@/assets/images/loader.svg" alt="Wait...">
                    </div>
                </div>
                <div class="buttons">
                    <button class="btn btn-dark" @click="$emit('close')">Закрыть</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
