<script setup>
</script>

<template>
    <div>
        <header>
            <a href="/"><img src="@/assets/images/logo.svg" class="header-logo" alt="GTC Gazprom"></a>
        </header>

        <div class="about">
            <div class="about-title">Ski-Pass 2.0</div>
            <div class="about-description">
                Мы изобрели ски-пасс заново! Теперь цена зависит от погоды <br>
                и очередей, пополните Вашу пластиковую карту онлайн в <br>
                день катания по специальным ценам. Стоимость ски-пассов в <br>
                онлайн-магазине зависит от:
            </div>

            <div class="about-variants desktop-only">
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-mountains-y.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        количества <br>
                        открытых трасс
                    </div>
                </div>
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-day-and-night-y.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        времени <br>
                        суток
                    </div>
                </div>
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-queue-y.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        размера очередей<br>
                        на склонах и в кассах
                    </div>
                </div>
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-weather-y.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        погодных<br>
                        условий
                    </div>
                </div>
            </div>
        </div>
        <div id="auth">
            <div class="auth-title">Начните пользоваться прямо сейчас</div>
            <div class="auth-description">Введите свой телефон, чтобы зарегистрироваться и покупать Ski-pass 2.0</div>
            <img src="@/assets/images/img-phone.png" alt="" class="phone-img desktop-only">
            <img src="@/assets/images/img-phone-mobile.png" alt="" class="phone-img mobile-only">
            <form action="#" method="post" class="auth-form auth-form-send-sms">
                <div class="phone-box">
                    <svg class="icon icon-phone">
                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-phone-wave"></use>
                    </svg>
                    <input type="text" name="phone" value="" placeholder="телефон">
                </div>
                <button class="btn btn-dark" type="submit" @click.prevent="$router.push({name: 'ski-pass'})">Получить код</button>
            </form>
            <form action="#" method="post" class="auth-form auth-form-send-code hide">
                <div class="mail-box">
                    <svg class="icon icon-mail">
                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-mail"></use>
                    </svg>
                    <input type="text" name="code" placeholder="Код из СМС">
                </div>
                <div class="auth-form-footer">
                    <a href="#">отправить еще раз</a>
                </div>
                <button class="btn btn-dark" type="submit">Войти</button>
                <div class="auth-form-footer">
                    Нажимая “Войти” я подтверждаю согласие <br>
                    с <a href="#">политикой обработаки конфиденциальных данных</a>
                </div>
            </form>
            <a href="/" class="auth-logo"><img src="@/assets/images/logo.svg" class="header-logo" alt="GTC Gazprom"></a>
        </div>

        <div class="about mobile-only">
            <div class="about-title-small">Стоимость скипасса зависит <br> от таких параметров:</div>
            <div class="about-variants">
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-weather-g.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        погодных<br>
                        условий
                    </div>
                </div>
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-day-and-night-g.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        времени <br>
                        суток
                    </div>
                </div>
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-mountains-g.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        количества <br>
                        открытых трасс
                    </div>
                </div>
                <div class="about-variant">
                    <div class="about-variant-icon">
                        <img src="@/assets/images/icon-queue-g.png" alt="">
                    </div>
                    <div class="about-variant-text">
                        размера очередей<br>
                        на склонах и в кассах
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
