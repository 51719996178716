<script>
export default {
    props: {
        url: String
    },
}
</script>

<template>
    <div class="popup" data-id="check-out">
        <div class="popup-container check-out">
            <div class="btn-close" @click="$emit('close')">
                <svg class="icon icon-close">
                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-close"></use>
                </svg>
            </div>
            <div class="content">
                <div class="head">
                    <svg class="icon icon-info-ex">
                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-warn"></use>
                    </svg>
                    <span>Внимание!</span>
                </div>
                <div class="text">
                    <p>Срок действия QR кода менее 10 минут. <br>Если Вы находитесь у кассы, нажмите кнопку «Продолжить»</p>
                </div>
                <div class="buttons">
                    <a href="#" class="btn btn-dark btn-close set-agree" @click.prevent="$emit('continue');$emit('close')">Продолжить</a>
                    <button class="btn" @click="$emit('close')">Назад</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
