<script>
import {maska} from 'maska';
import {md5} from '@/hooks/md5Helper';
import app from "@/main";
import {addURLParam} from "@/hooks/fnHelper";

export default {
    directives: {maska},
    props: {
        show: {
            type: Boolean,
            default: false
        },
        payload: {
            type: Object || null,
            default: null
        }
    },
    data() {
        return {
            needCode: true,
            form: {
                phone: '+7',
                smscode: '',
                apsid: '',
            },
            repeatCodeTimer: 0,
            repeatCodeTimerInt: null,
            repeatCodeCounter: 0,
            loading: false,
            gidLoading: false,
            at: 1,
            repeatCodeCounterLimit: 2000,
        }
    },

    mounted() {
        this.$nextTick(() => {

            setTimeout(() => {
                if (this.$refs.phone) {
                    this.$refs.phone.focus();
                }
            }, 100);
        });
        // console.log('AuthPopup mounted');
    },

    watch: {
        repeatCodeCounter: function (val) {
            if (val >= this.repeatCodeCounterLimit) {
                this.at = 0;
            } else {
                this.at = 1;
            }
        },
        show: function (val) {
            if (val && !this.available) {
                this.$toast.error('Авторизация временно не доступна!');
                this.close();
            }
        }
    },

    computed: {
        mask() {
            let phone = this.form.phone.replace(/[^0-9]/g, '');
            return phone.length && phone[0] === '3' ? '+### ## ### ## ##' : '+# ### ### ## ##'
        },

        digits() {
            return Array(this.repeatCodeCounter <= this.repeatCodeCounterLimit ? 3 : 6).fill('');
        },
        available() {
            const loginRoute = app.config.globalProperties.$serverRouters.find(item => item.tagRoute === 'login');
            if (!loginRoute || !loginRoute.enabled || (loginRoute.isDebug && !this.$root.testMode)) {
                return false;
            }
            return true;
        }
    },

    methods: {
        onBtnClose() {
            if (this.payload?.onCancel instanceof Function) {
                this.payload.onCancel();
            }
            this.close();
        },
        close() {
            if (this.$route.meta.authOnly && !this.$root.isAuth) {
                this.$router.push({name: 'home'});
            }

            this.$emit('close');
        },
        changePhone() {
            this.needCode = true;
            this.repeatCodeCounter = 0;
        },
        getCode() {
            /*this.needCode = false;
            setTimeout(() => {
                this.$refs.smscode[0].focus();
            });
            return;
             console.log('this.$root.host', this.$root.host);*/

            /*this.$cookies.set('server_sid', '9E82C513F9F3847CA7A3EAD548DBE42F');
            this.$cookies.set('server_userid', 3574);

            this.$root.user = {
                "id": 3574,
                "email": "x_drw@bk.ru",
                "email2": "",
                "acctype": "Browser+Mobile",
                "fname": "Дмитрий",
                "mname": "Сергеевич",
                "sname": "Батминов",
                "phone": "79284525721",
                "gender": 1,
                "id_1chotel": "V00000059355",
                "city": "Краснодарский край, г Сочи",
                "avatar_file_id": 0,
                "user_posted": 1,
                "datereg": 1544020620,
                "birthday": 546652800,
                "date_confirm_email": 1710435433,
                "date_confirm_email2": 0,
                "date_upload_profbilet": 0,
                "profkomphone": "",
                "sd": 1711042144
            };
            this.$root.isAuth = true;
            this.$emit('close');
            console.log('this.payload.onAuth', this.payload?.onAuth, this.payload?.onAuth instanceof Function);
            if (this.payload?.onAuth instanceof Function) {
                this.payload.onAuth();
            }

            return;*/

            //console.log(JSON.stringify({"cmd": "sendsms", "cellnum": this.form.phone}));

            ym(51222278, 'reachGoal', 'market_auth_code');

            if (!this.loading && this.repeatCodeTimer <= 0) {

                let phone = this.form.phone.replace(/\D/g, '');

                let phoneLength = 11;
                if (parseInt(phone[0]) === 3 && parseInt(phone[1]) === 7 && parseInt(phone[2]) === 5) {
                    phoneLength = 12;
                }

                if (phone.length !== phoneLength) {
                    this.$toast.warning('Неверный номер телефона.');
                    setTimeout(() => {
                        this.$refs.phone.focus();
                    });
                    return;
                }

                let time = Date.now();
                this.loading = true;

                const params = new URLSearchParams()
                params.append('phone', phone);
                params.append('at', this.at);
                params.append('hash', md5(phone + '' + time));
                params.append('time', "" + time);
                params.append('deviceid', "1e3d40388488eeb14b9702ede52cb33d");
                params.append('sid', this.$cookies.get('server_sid'));

                this.axios.post('https://get.polyanaski.ru/getall.dll/postAuthPhoneNew', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(({data}) => {
                    console.log('getCode data', data);
                    /*if(data.includes('HTTP/1.1')){
                        data = JSON.parse(data.replace(/HTTP\/1\.1 500[\s\S]*$/, ''));
                    }*/
                    if (data.error === 0) {
                        this.needCode = false;
                        this.form.apsid = data.sid;

                        this.repeatCodeTimer = 90;

                        setTimeout(() => {
                            this.$refs.smscode[0].focus();
                        }, 50);

                        this.repeatCodeTimerInt = setInterval(() => {
                            this.repeatCodeTimer--;
                            if (this.repeatCodeTimer <= 0) {
                                this.repeatCodeTimer = 0;
                                clearInterval(this.repeatCodeTimerInt);
                            }
                        }, 1000);
                    } else {
                        let serverError;
                        switch (data.error) {
                            case 1:
                                serverError = 'Ошибка отправки кода на данный номер';
                                break;
                            case 2:
                                serverError = 'Ошибка на стороне БД';
                                break;
                            default:
                                serverError = 'Неизвестная ошибка сервера!';
                                break;
                        }
                        this.$toast.error(serverError);
                    }
                }).catch((error) => {
                    this.$toast.error(error.message);
                    this.$toast.error(error.request.response);
                }).finally(() => {
                    this.loading = false;
                    this.repeatCodeCounter++;
                });
            }
        },
        auth() {
            ym(51222278, 'reachGoal', 'market_auth_auth');

            if (!this.loading) {
                this.loading = true;
                const params = new URLSearchParams()
                // params.append('sid', this.$cookies.get('server_sid'));
                params.append('sid', this.form.apsid);
                params.append('code', this.form.smscode);
                params.append('deviceid', "1e3d40388488eeb14b9702ede52cb33d");

                this.axios.post('https://get.polyanaski.ru/getall.dll/postauthcode', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }).then(({data}) => {
                    if (data.error === 0) {
                        console.log('AuthPopup 238 host', this.$root.host);
                        this.$cookies.remove('server_sid', null, this.$root.host);
                        this.$cookies.remove('server_userid', null, this.$root.host);
                        this.$cookies.set('server_sid', data.sid, null, null, this.$root.host);
                        this.$cookies.set('server_userid', data.userid, null, null, this.$root.host);
                        if (data.auth) {
                            this.axios.get('https://get.polyanaski.ru/getall.dll/userdata?sid=' + data.sid).then(({data}) => {
                                console.log('userdata data', data);
                                this.$root.isAuth = true;
                                this.$root.user = data;

                                this.form = {
                                    phone: '',
                                    smscode: '',
                                    apsid: '',
                                };
                                this.needCode = true;
                                this.repeatCodeTimer = 0;
                                clearInterval(this.repeatCodeTimerInt);
                                this.repeatCodeTimerInt = null;

                                setTimeout(() => {
                                    if (this.payload?.onAuth instanceof Function) {
                                        this.payload.onAuth();
                                    }
                                    this.close();
                                    // this.$router.go();
                                });
                            }).finally(() => {
                                this.loading = false;
                            });
                        }
                        this.loading = false;
                    } else {
                        let serverError;
                        switch (data.error) {
                            case 1:
                                serverError = 'Неверный идентификатор сессии';
                                break;
                            case 2:
                                serverError = 'deviceid не соответствует сессии';
                                break;
                            case 3:
                                serverError = 'Неверный код';
                                break;
                            case 4:
                                serverError = 'Ошибка на стороне БД';
                                break;
                            case 5:
                                serverError = 'Нет пользователя, соответствующего сессии';
                                break;
                            default:
                                serverError = 'Неизвестная ошибка сервера!';
                                break;
                        }
                        this.$toast.error(serverError);
                        this.loading = false;
                    }
                }).catch(() => {
                    this.loading = false;
                });
            }
        },

        onFocus() {
            this.$nextTick(() => {
                this.$refs.phone.selectionStart = this.$refs.phone.value.length;
            });
        },

        moveToNextInput(index, val) {
            // console.log('moveToNextInput', val, val[0], index);
            // 345345
            val = val.replace(/\D/g, '');
            if (!val.length) {
                this.digits[index] = '';
                return;
            }

            let nextIndex = index + 1;
            this.digits[index] = val[0];
            if (nextIndex < this.digits.length) {
                this.$refs.smscode[nextIndex].focus();

                if (val.length > 1) {
                    this.moveToNextInput(nextIndex, val.slice(1), true);
                    return;
                }
            }

            if (this.digits.join('').length === (this.at ? 3 : 6)) {
                this.form.smscode = this.digits.join('');
                this.auth();
            }
        },

        moveToPrevInput(index) {
            const prevIndex = index - 1;
            if (this.digits[index]) {
                this.digits[index] = '';
            } else {
                this.digits[prevIndex] = '';
            }

            if (index > 0) {
                this.$refs.smscode[prevIndex].focus();
            }
        },

        async gidAuth() {
            if (!this.gidLoading) {
                this.gidLoading = true;

                let mrktsid = this.$cookies.get('server_sid') ?? '';

                if (!mrktsid) {
                    try {
                        const {data} = await this.axios.get("https://ski-pass.polyanaski.ru/skipass2/API?paramline=" + btoa(JSON.stringify({
                            "cmd": "test",
                            "mrktsid": mrktsid
                        })))

                        console.log('data', data);
                        mrktsid = data.sp22sid;

                        console.log('mrktsid', mrktsid);
                    } catch (e){}
                }

                this.$cookies.set("gazprom_sid", mrktsid, null, null, this.host);

                if(this.$route.name === 'booking'){
                    addURLParam(this.$router, 'send_data', 'Y');
                }

                this.axios.get('https://svod.polyanaski.ru/api/market/auth/login?ssoname=SSO_gid&urlcallback=' + encodeURIComponent(location.href) + '&mrktsid=' + mrktsid).then(({data}) => {
                    location.href = data;
                }).catch(() => {
                    this.gidLoading = false;
                });
            }
        }
    }
}
</script>

<template>
    <div class="popup popup-auth"
         data-id="auth">
        <div class="popup-container auth">
            <div class="btn-close"
                 @click="onBtnClose">
                <svg class="icon icon-close">
                    <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-close"></use>
                </svg>
            </div>
            <div id="auth">
                <div class="auth-title">Начните пользоваться прямо сейчас</div>
                <div class="auth-description"
                     v-if="needCode">Введите свой номер телефона, чтобы зарегистрироваться и
                    покупать билеты онлайн
                </div>
                <div class="auth-description mb10"
                     v-else>
                    <template v-if="repeatCodeCounter <= repeatCodeCounterLimit">
                        Введите последние 3 цифры телефонного номера, с которого был совершён входящий звонок на номер <b
                        style="white-space: nowrap">{{ form.phone }}</b>
                    </template>
                    <template v-else>
                        Введите код из СМС, отправленный&nbsp;на&nbsp;номер
                        <br>
                        <b>{{ form.phone }}</b>
                    </template>
                </div>
                <img src="@/assets/images/img-phone-summer.png"
                     alt=""
                     class="phone-img desktop-only">
                <!--                <img src="@/assets/images/img-phone-mobile.png" alt="" class="phone-img mobile-only">-->
                <form action="#"
                      method="post"
                      class="auth-form auth-form-send-sms">
                    <template v-if="needCode">
                        <div class="phone-box">
                            <svg class="icon icon-phone">
                                <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-phone"></use>
                            </svg>
                            <input type="text"
                                   name="phone"
                                   v-model="form.phone"
                                   ref="phone"
                                   inputmode="tel"
                                   v-maska="mask"
                                   @focus="onFocus"
                                   placeholder="Номер телефона">
                        </div>
                        <button class="btn btn-dark"
                                :class="{'loading': loading}"
                                @click.prevent="getCode">
                            Получить код
                        </button>
                        <div class="other-services-auth">Или войдите с помощью</div>
                        <button class="btn btn-gazprom-gid"
                                :class="{'loading': gidLoading}"
                                @click.prevent="gidAuth">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 86.79 29.83">
                                <rect fill="#3174f4"
                                      width="29.83"
                                      height="29.83"
                                      rx="8.11"
                                      ry="8.11"/>
                                <path fill="#fff"
                                      d="M18.21,2.44c.39,.64,1.32,2.17,1.73,3.96,.53,2.19,.49,3.51,.3,5.06-.42,2.76-1.66,4.83-2.19,5.55-.38-.49-.94-1.47-1.32-2.38,0,0-.72-1.74-.91-3.43-.22-1.79-.06-3.6,.45-5.32,.47-1.66,1.42-3.14,1.73-3.64l.04-.06c.02,.05,.08,.14,.16,.27h0Zm-.84,11.32c.04,1.36,.42,2.23,.68,2.68,.23-.45,.68-1.47,.64-2.79-.04-1.36-.49-2.34-.64-2.57-.44,.83-.67,1.75-.68,2.68h0Zm2.19-1.43c.15-.53,.23-1.4,.26-2.23,0-.83-.08-2.23-.49-3.74-.42-1.47-1.21-2.87-1.32-3.06-.11,.19-.72,1.32-1.28,3.1-.15,.57-.42,1.7-.45,2.83-.04,1.36,.15,2.72,.23,3.1,0-.45,0-1.96,.3-3.21,.26-1.21,.79-2.68,1.25-3.4,.45,.79,1.02,2.45,1.25,3.43,.19,.98,.3,2.26,.26,3.17Zm-1.21,7.7c-.88-.9-2.33-.91-3.22-.02,0,0-.02,.02-.02,.02v-2.53h5.93v5.55c-1.53,1.64-3.68,2.57-5.93,2.57-4.48,0-8.11-3.61-8.12-8.09,0,0,0-.02,0-.03,0-4.45,3.62-8.11,8.15-8.11v6.08c-1.17,0-2.3,.42-3.21,1.32-.21,.21-.4,.44-.57,.68-1.17,1.77-1.02,4.19,.57,5.77,.85,.85,2.01,1.32,3.21,1.32,1.17,0,2.3-.45,3.21-1.32,.91-.87,.91-2.3,0-3.21h0Z"/>
                                <path fill="#2f4573"
                                      d="M37.63,3.42h11.58v4.95h-6.56V26.23h-5.05V3.42h.03Zm23.91,10.15l-5.27,12.69h-4.9V3.45h4.9l.04,12.59,5.23-12.59h4.87V26.26h-4.87V13.57h0Zm11.11,12.66v2.69h-4.47v-7.57h1.69c.9-.79,1.77-3.48,1.73-9.36l-.04-3.69V3.42h12.84V21.35h2.38v7.57h-4.51v-2.69h-9.63Zm3.89-17.89v3.08c0,4.41-.65,7.64-1.59,9.97h4.44V8.33s-2.85,0-2.85,0Z"/>
                            </svg>
                        </button>
                    </template>
                    <template v-else>
                        <div class="mail-box"
                             style="border-bottom: none">
                            <!--                            <svg class="icon icon-mail">
                                                            <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-mail"></use>
                                                        </svg>-->

                            <div class="digit-inputs"
                                 :class="{'is-call': repeatCodeCounter <= repeatCodeCounterLimit}">
                                <input
                                    v-for="(digit, index) in digits"
                                    :key="index"
                                    v-model="digits[index]"
                                    type="text"
                                    ref="smscode"
                                    inputmode="tel"
                                    autocomplete="one-time-code"
                                    @input="moveToNextInput(index, $event.target.value)"
                                    @keydown.delete.prevent="moveToPrevInput(index)"
                                >
                            </div>
                        </div>
                        <div class="auth-form-repeat-code">
                            <template v-if="repeatCodeTimer<=0">
                                <a href
                                   @click.prevent="getCode">получить код
                                    <template v-if="repeatCodeCounter>=repeatCodeCounterLimit"> SMS</template>
                                </a>
                                <a href
                                   @click.prevent="changePhone">изменить номер</a>
                            </template>
                            <a href
                               v-else
                               @click.prevent>Повторить звонок можно через {{ repeatCodeTimer }} сек.</a>
                        </div>
                        <button class="btn btn-dark"
                                :class="{'loading': loading}"
                                @click.prevent="auth">Войти
                        </button>
                        <div class="auth-form-footer">
                            Нажимая “Войти” я подтверждаю согласие <br>
                            с <a
                            href="https://polyanaski.ru/upload/medialibrary/rules/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85%20%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85%20%D0%9E%D0%9E%D0%9E%20%D0%A1%D0%B2%D0%BE%D0%B4%20%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%88%D0%BD%D0%BB.pdf"
                            target="_blank">политикой обработки конфиденциальных данных</a>
                        </div>
                    </template>
                </form>

                <!--                <a href="/" class="auth-logo"><img src="@/assets/images/logo.svg" class="header-logo" alt="GTC Gazprom"></a>-->
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>
