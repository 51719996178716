<script>
import app from "@/main";
import {apiErrorHandler} from "@/hooks/fnHelper";

export default {
    computed: {
        toServerRoute() {
            const serverRouters = app.config.globalProperties.$serverRouters;
            return serverRouters.find(item => item.enabled && item.visible && !item.isDebug);
        }
    },
}
</script>

<template>
    <section id="body">
        <div id="content">
            <div class="content-box min-h-530 content-box-i notification-box">
                <div class="notification-icon">
                    <svg class="icon icon-info-ok">
                        <use xlink:href="@/assets/images/svg_icons.svg#sprite-icon-info-i"></use>
                    </svg>
                </div>
                <div class="notification-title">404 | Страница не найдена ☹</div>
                <div class="notification-content"></div>
                <div class="notification-btn-box">
                    <router-link :to="{name: toServerRoute.tagRoute}" class="btn btn-blue-dark">В магазин</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<style></style>
