<script>
export default {}
</script>

<template>
    <div class="content-box min-h-100vh content-box-i wait-box">
        <div class="wait-content">
            <h3>Уважаемые гости, на странице ведутся технические работы.
                <br> Приобрести билеты Вы можете в кассах Курорта «Газпром Поляна».
                <br> Приносим извинения за доставленные неудобства.</h3>
        </div>
    </div>

</template>

<style lang="scss"></style>
